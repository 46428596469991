import { captureException } from '@sentry/remix';

export function propagateError(e: unknown, msg?: string) {
  const error = e instanceof Error ? e : new Error(String(e));

  Error.captureStackTrace(error);
  console.error(msg, error.message, error.stack);

  captureException(error);

  return error;
}
